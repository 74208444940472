import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import search from "../../assets/images/search.svg";
import {
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setEquipment,
  setTypes,
  setTotalDoc,
  addfilter,
  saveQAContent,
  setToaster,
  setActivePage
} from "../../redux/actions";
import KeycloakUserService from "../../keycloak/KeycloakUserService.js";
import { searchApi } from "../apiCall";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const ResultSearchBox = (props) => {
  const NLP_URL = process.env.REACT_APP_NLP_BASE_URL;
  //check if the NLP_URL starts with http or https
  const isHTTP = NLP_URL.startsWith("http://");
  const NLP_IP = isHTTP ? NLP_URL.slice(7) : NLP_URL.slice(8);
  const WEBSOCKET_URL = `${isHTTP ? "ws":"wss"}://${NLP_IP}/autocomplete/ws/autocomplete`;
  console.log("WEBSOCKET_URL", WEBSOCKET_URL);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState(props.searchedWord);
  const currentQueryRef = useRef("");
  const websocketRef = useRef(null);
  useEffect(() => {
    setQuery(props.searchedWord);
  }, []);

  useEffect(() => {
    websocketRef.current = new WebSocket(WEBSOCKET_URL);

    websocketRef.current.onmessage = (event) => {
      let suggestion = event.data;
      suggestion = suggestion.slice(6);
      if (suggestion.startsWith(currentQueryRef.current)) {
        suggestion = suggestion.replace(/<unk>/g, "");
        suggestion = suggestion.replace(/[^a-zA-Z0-9 ]/g, "");
        setSuggestions((prev) => [...new Set([...prev, suggestion])]);
      }
    };

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowSuggestions(true);
    console.log("value", value);
    currentQueryRef.current = value;
    setSuggestions([]);

    if (value.length > 0) {
      if (
        websocketRef.current &&
        websocketRef.current.readyState === WebSocket.OPEN
      ) {
        websocketRef.current.send(`xschematic:`+value);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      localStorage.clear();
      props.saveSearchWord("");
      window.location.reload(false);
      KeycloakUserService.doLogout();
      props.history.push({
        pathname: "/",
      });
    }
  }
  const getDocs = async (e) => {
    query !== props.searchedWord && props.saveQAContent("");
    props.saveSearchWord(query);
    props.addfilter(JSON.stringify({}));
    handleSessionOut();
    e.preventDefault();
    props.ableLoading(true);
    let filterData = [];

    var queryToExecute = {
        q: query !== ""?query:"*:*",
        rows: 10,
        start: 0,
        filter: filterData,
      };

    await searchApi(queryToExecute)
      .then((res) => {
        props.saveSearchResult(JSON.stringify(res.data.response.docs));
        res.data.response.authDocs?.length > 0
          ? props.saveSearchResultAuthDocs(
              JSON.stringify(res.data.response.authDocs)
            )
          : props.saveSearchResultAuthDocs("");
        if (res.data.equipment !== undefined) {
          props.setEquipment(res.data.equipment);
        }
        if (res.data.type !== undefined) {
          props.setTypes(res.data.type);
        }
        props.setTotalDoc(res.data.response.numFound);
        props.setActivePage(1);
        props.ableLoading(false);

      })
      .catch((error) => {
        props.setToaster(true);
        props.ableLoading(false);
        if (
          error.response.data === "Access denied" ||
          // eslint-disable-next-line no-magic-numbers
          error.response.status === 403
        ) {
          KeycloakUserService.doLogout();
        }
      });
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setShowSuggestions(false);
    
  };
  const renderSuggestion = (suggestion) => {
    const matchIndex = suggestion.toLowerCase().indexOf(query.toLowerCase());
    if (matchIndex === -1) return suggestion;

    const before = suggestion.slice(0, matchIndex);
    const match = suggestion.slice(matchIndex, matchIndex + query.length);
    const after = suggestion.slice(matchIndex + query.length);

    return (
      <>
        {before}
        <span className="font-normal">{match}</span>
        <span className="font-bold">{after}</span>
      </>
    );
  };


  const onChange = (e) => {
    setQuery(e.target.value);
  };
  const onSearch = (e) => {
    getDocs(e);
  };
  return (
    <form className="flex flex-col md:flex-row border-t border-b pt-7 pb-7 resultbox">
      <div>
        {/* <img
          className="h-10 ml-14"
          src={lexx_logo}
          alt="vanoord_marine_ingeunity logo"
        /> */}
      </div>
      <div className="font-bold text-xl leading-[22px] text-[#0971CE] flex flex-col items-center ml-0 md:ml-10">
        <div style={{ paddingTop: "10px", paddingLeft: "20px" }}>
          xSchematicPro
        </div>
      </div>
      <div className={`relative`}>
        <div className="">
          <span className="material-symbols-outlined relative top-2.5 md:top-2 left-5 md:left-9 z-10 ">
            <img
              className="w-3 md:w-5 mb-2.5 material-symbols-outlined "
              src={search}
              alt="search logo"
            ></img>
          </span>
          <input
            className={`w-[320px] md:w-[564px]  h-[22px]  md:h-[44px] text-[5] pl-7 md:pl-14 focus:outline-none rounded-[28px] border shadow-[0px_0px_5px_0px_rgba(0,0,0,0.30)] placeholder-gray-400 text-[7px] md:text-[14px]`}
            name="input"
            onChange={handleInputChange}
            type="text"
            value={query}
            placeholder="Ask me anything?"
            title="Search"
          />
          {/* <span className="material-symbols-outlined relative top-2 right-10">
            mic
          </span> */}
        </div>
        {showSuggestions && suggestions.length > 0 && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-b-lg rounded-2xl shadow-lg">
            {suggestions.slice(0, 10).map((suggestion, index) => (
              <li
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {renderSuggestion(suggestion)}
              </li>
            ))}
          </ul>
        )}
      </div>
      <Link to={"/searchResult"} className="">
        <div className="searchbuttononresultsearchbox pt-5 md:pt-0">
          <button
            type="submit"
            className="bg-[#0971CE] h-[45px] w-[120px] rounded-[20px] text-white "
            onClick={(e) => {
              onSearch(e);
            }}
          >
            Search
          </button>
        </div>
      </Link>
    </form>
  );
};
const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    pinnedSearches: state.pinnedSearches,
  };
};
export default connect(mapStateToProps, {
  saveSearchWord,
  saveSearchResultAuthDocs,
  saveSearchResult,
  setEquipment,
  setTypes,
  addfilter,
  setTotalDoc,
  saveQAContent,
  setToaster,
  setActivePage
})(ResultSearchBox);
