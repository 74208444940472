import { connect } from "react-redux";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { Icon } from 'semantic-ui-react';
import {
  toolbarPlugin,
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState, useRef } from "react";

const PdfView = (props) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState('all');
  const [selectedAreaNumber, setSelectedAreaNumber] = useState(0);
  const [selectedExactAreaNumber, setSelectedExactAreaNumber] = useState(0);
  const [selectedSimilarAreaNumber, setSelectedSimilarAreaNumber] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const viewerRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  var screenWidth = window.screen.width;
  const [areas, setAreas] = useState(props.docDetail.metaArray);
  const [sameTextAreas, setSameTextAreas] = useState(props.docDetail.exactDataMetaArray);
  const handleDocumentLoad = () => setDocumentLoaded(true);
  const highlightword = props.docDetail.result.search_content[0];

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const { ShowSearchPopover, Zoom, ZoomIn, ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );


  const renderHighlights = (props) => {
    return (
      <div>
        {areas.map((area, idx) => {
          if (area.pageIndex === props.pageIndex && (selectedOption === 'similarResults' || selectedOption === 'all')) {
            return (
              <div
                key={idx}
                className="highlight-area"
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4,
                    cursor: "pointer",
                  },
                  props.getCssProperties(area, props.rotation)
                )}
              />
            );
          }
          return null;
        })}
      </div>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
    toolbarPlugin: {

    },
  });

  const { toolbarPluginInstance } = defaultLayoutPluginInstance;
  const { searchPluginInstance } = toolbarPluginInstance;
  const { highlight } = searchPluginInstance;

  useEffect(() => {
    if (isDocumentLoaded) {
      highlight(highlightword);
      if (areas.length > 0) {
        jumpToHighlightArea(areas[0]);
      }
    }
  }, [isDocumentLoaded]);

  useEffect(() => {
    const viewerContainer = viewerRef.current;

    const handleMouseMove = (event) => {
        setMousePosition({ x: event.clientX, y: event.pageY });
    };

    const handleMouseOver = (event) => {
      const target = event.target;
      if (target.tagName === 'A' && target.getAttribute('data-target') === 'external') {
        setShowTooltip(true);
      }
    };


    const handleMouseOut = (event) => {
      const target = event.target;
       if (target.tagName === 'A' && target.getAttribute('data-target') === 'external') {
        setShowTooltip(false)
      }
    };

    if (viewerContainer) {
      viewerContainer.addEventListener('mousemove', handleMouseMove);
      viewerContainer.addEventListener('mouseover', handleMouseOver);
      viewerContainer.addEventListener('mouseout', handleMouseOut);
    }


    return () => {
      if (viewerContainer) {
        viewerContainer.removeEventListener('mousemove', handleMouseMove);
        viewerContainer.removeEventListener('mouseover', handleMouseOver);
        viewerContainer.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [isDocumentLoaded, mousePosition]);

  const incrementNumber = () => {
    setSelectedAreaNumber((prevNumber) => prevNumber + 1);
    jumpToHighlightArea(areas[selectedAreaNumber + 1]);
  };

  const decrementNumber = () => {
    jumpToHighlightArea(areas[selectedAreaNumber - 1]);
    setSelectedAreaNumber((prevNumber) => Math.max(0, prevNumber - 1));
  };

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
  });
  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;

  return (
    <div className="rpv-core__viewer">
      {
        areas.length ? <div style={{ textAlign: 'right', marginTop: '15px', marginBottom: '15px', marginRight: '50px' }}>
          <label>
            <span>{areas.length} Results found {`(${selectedAreaNumber + 1} / ${areas.length})`}</span>
          </label>
          <button style={{ marginLeft: '20px' }} disabled={selectedAreaNumber === 0} onClick={decrementNumber}>
            <Icon name="arrow up" />
          </button>
          <button disabled={selectedAreaNumber === areas.length - 1} onClick={incrementNumber}>
            <Icon name="arrow down" />
          </button>
        </div> : <></>
      }
      {showTooltip && (
        <div className="absolute z-10 bg-gray-800 text-white p-2 rounded" style={{ transform: `translate(${mousePosition.x - 120}px, ${Math.abs(mousePosition.y - 400)}px)` }}>
          Please click here for more details
        </div>
      )}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div style={{ height: "720px" }} ref={viewerRef}>
          <Viewer
            fileUrl={props.fileLinkRedux}
            plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
            defaultScale={1.5}
            initialPage={props.docDetail.meta.pageIndex - 1}
          />
        </div>
      </Worker>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fileLinkRedux: state.fileLinkRedux,
    outLineRedux: state.outLineRedux,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps)(PdfView);